import React, { useState, useEffect } from "react";
import { Label, Row, Col, Input, Button, FormGroup, InputGroup, InputGroupText } from "reactstrap";
import useAxios from 'hooks/useAxios';
import { Formik } from 'formik';
import { default as ReactSelect } from "react-select";
import { Option, LimitedChipsContainer } from 'components/Options';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment/moment";
import Calendar from "assets/icon/calendar-icon.svg";
import CancleIcon from 'assets/icon/icon-icon-options-not-eligible.svg';

let coveredentitydata = [];
let organizationdata = [];
let orgvalue = true;
let orgId;
let hasDatePopupOpen = false;
let Startingyear;
let endingyear;
let StartingMonth;
let endingMonth;

const SearchContainer = (props) => {

    const API = useAxios();
    const {
        onSearch,
        onReset,
        cleartabledata
    } = props;

    const [monthid, setmonth] = useState(endingMonth);
    const [yearvalue, setyear] = useState(endingyear);
    const [invoiceid, setInvoiceid] = useState(1);
    const [selectedOptionCE, setOptSelectedCE] = useState({ optionSelected: '-1' });
    const [loading, setLoading] = useState(false);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [selectedyear, setSelectedYear] = useState(new Date());
    const [maxmont, setmaxmonth] = useState(new Date());

    const handleMouseLeave = () => {
        hasDatePopupOpen = false;
    };

    const handleDateClick = (id) => {
        if (hasDatePopupOpen) {
            hasDatePopupOpen = false;
            return;
        };

        const calendarButton = document.getElementById(id);
        if (calendarButton) {
            calendarButton.click();
            hasDatePopupOpen = true;
        };
    };

    const { token_type, access_token, profile } =
        JSON.parse(sessionStorage.getItem('pharmaforce')) || {};

    const { sub } = profile || {};

    const selectedinvoicetypeoptions = [
        { id: '1', label: 'Financial Management' },
        { id: '2', label: 'Invoice' },
    ];

    const GetOrganization = async () => {

        setLoading(true);
        organizationdata = [];
        setOptSelectedCE({ optionSelected: '-1' });
        coveredentitydata = [];
        try {
            const { data } = await API.get(`/search/organizationlist/${sub}`, {
                hasDefaultURL: false,
            });
            if (data) {
                for (var x in data) {
                    organizationdata.push({ "value": data[x].organizationID, "label": data[x].organizationName });
                };
            }
            setLoading(false)
        } catch (err) {
        };
    };

    const fetchStartingYearMonth = async () => {

        setLoading(true);
        try {
            const { data } = await API.get('/search/GetStartingYearMonth', {
                hasDefaultURL: false,
            });
            if (data) {
                Startingyear = data[0].year;
                StartingMonth = data[0].month;
            };
            setLoading(false)
        } catch (err) {
        };
    };

    const fetchGetEndingYearMonth = async () => {

        setLoading(false)
        try {
            const { data } = await API.get('/search/GetEndingYearMonth', {
                hasDefaultURL: false,
            });
            if (data) {

                endingyear = data[0].year;
                endingMonth = data[0].month;
                setSelectedYear(new Date(endingyear, 11, 28))
                setyear(endingyear);
                setmonth(endingMonth);
                setSelectedDate(new Date(endingyear, endingMonth - 1, 28))
                setmaxmonth(new Date(endingyear, endingMonth - 1, 28))
            };
            setLoading(false)
        } catch (err) {
        };
    };

    useEffect(() => {
        fetchGetEndingYearMonth();
        fetchStartingYearMonth();
        GetOrganization();
    }, []);

    const fetchCoveredentity = async () => {

        setLoading(true);
        setOptSelectedCE({ optionSelected: '-1' });
        coveredentitydata = [];
        try {
            const { data } = await API.get(`/search/coveredentitylist/${orgId}`, {
                hasDefaultURL: false,
            });
            if (data) {
                for (var x in data) {
                    coveredentitydata.push({ "value": data[x].ceid, "label": data[x].ceName });
                };
            };
        } catch (err) {
            setLoading(false);
        };
    };

    const fetchinvoicetype = async (e) => {
        setInvoiceid(e.target.value)
        var invoicetypeid = e.target.value;
        cleartabledata();
        setOptSelectedCE({ optionSelected: '-1' });
        fetchStartingYearMonth();
        fetchGetEndingYearMonth();
        setmaxmonth(new Date(endingyear, endingMonth, 28));
        setSelectedDate(new Date(endingyear, endingMonth - 1, 28))
        setSelectedYear(new Date(endingyear, 11, 28));
        coveredentitydata = [];
        orgvalue = true;
        if (invoicetypeid == 1) {
            GetOrganization();
        }
        else {
            organizationdata = [];
        }
    };

    const handelYear = (date) => {
        if (!date) return "";
        setSelectedDate();
        setSelectedYear(date)
        const year = moment(new Date(date)).format("YYYY");
        setyear(year);
        let currentyear = new Date().getFullYear();
        let yearvalue = parseInt(year);
        if (currentyear === yearvalue) {
            fetchStartingYearMonth();
            fetchGetEndingYearMonth();
            setmaxmonth(new Date(endingyear, endingMonth, 28));
            setSelectedDate(new Date(endingyear, endingMonth, 28));
        }
        else {
            let monthdata = new Date(endingyear, 11, 28);
            setmaxmonth(monthdata)
            setSelectedDate(monthdata)
            setmonth(12);
        }
    };

    const formatMonth = (date) => {
        setSelectedDate();
        if (!date) return "";
        setSelectedDate(date)
        const options = { month: "long" };
        const month = moment(new Date(date)).format("MM");
        const monthint = parseInt(month)
        setmonth(monthint)
    };

    const reset = () => {
        setInvoiceid(1);
        fetchStartingYearMonth();
        fetchGetEndingYearMonth();
        setSelectedDate();
        setmaxmonth(new Date(endingyear, endingMonth, 28));
        setSelectedYear(new Date(endingyear, 11, 28));
        organizationdata = [];
        setOptSelectedCE({ optionSelected: '-1' });
        coveredentitydata = [];
        orgId = 0
        orgvalue = true;
        GetOrganization();
        onReset(true);
    };

    const handleCoverentityChange = async (selected) => {
        setOptSelectedCE({
            optionSelected: selected
        });
    };

    const cancelmonth = async () => {
        setSelectedDate();
        setmonth(0);
    };

    const handleOrganizationchange = async (e) => {
        orgId = e.target.value;
        orgvalue = false;
        fetchCoveredentity();
    };

    const handleSubmit = async (val, actions) => {

        var selectedvalue = selectedDate;
        let values = {}
        values.invoicetype = '1'
        values.organizationID = orgId
        let optCe = [];
        for (var x in selectedOptionCE.optionSelected) {
            var rw = selectedOptionCE.optionSelected[x];
            optCe.push(rw.value);
        };
        if (optCe > [0]) { values.optCe = optCe }
        values.year = yearvalue;
        values.month = monthid;
        await onSearch(values);
    };

    return (
        <Formik
            initialValues={''}
            onSubmit={handleSubmit}
            enableReinitialize={true}
            children={(props) => {
                const { handleSubmit } = props;
                return (
                    <div>
                        <h5 className='f-15 text-primary font-weight-700'>
                            Invoices
                        </h5>
                        <Row sm={5}>
                            <Col md={2} sm={6} xs={6}>
                                {' '}
                                <FormGroup>
                                    <Label for='InvoicetypeID'>Invoice Type</Label>
                                    <Input
                                        type='select'
                                        onChange={(e) => {
                                            fetchinvoicetype(e);
                                        }}
                                        value={invoiceid}
                                    >
                                        {selectedinvoicetypeoptions.map((status) => {
                                            const { id, label } = status;
                                            return (
                                                <option key={id} value={id}>
                                                    {label}
                                                </option>
                                            );
                                        })}
                                    </Input>
                                </FormGroup>
                            </Col>
                            <Col md={2} sm={6} xs={6}>
                                {' '}
                                <FormGroup>
                                    <Label for='OrganizationID'>Organization</Label>
                                    <Input
                                        type='select'
                                        onChange={(e) => handleOrganizationchange(e)}
                                        required
                                    >
                                        <option value='0'>Select...</option>
                                        {organizationdata.map((status) => {
                                            const { value, label } = status;
                                            return (
                                                <option key={value} value={value}>
                                                    {label}
                                                </option>
                                            );
                                        })};
                                    </Input>

                                </FormGroup>
                            </Col>
                            <Col md={2} sm={6} xs={6}>
                                {' '}
                                <FormGroup>
                                    <Label for='CoveredEntityID'>Covered Entity</Label>
                                    <ReactSelect
                                        name='CoveredEntity'
                                        options={coveredentitydata}
                                        isMulti
                                        closeMenuOnSelect={false}
                                        hideSelectedOptions={false}
                                        optionSelected={true}
                                        components={{
                                            Option, ValueContainer: LimitedChipsContainer
                                        }}
                                        isSearchable="true"
                                        onChange={handleCoverentityChange}
                                        value={selectedOptionCE.optionSelected}
                                        className='claim_info'
                                        maxMenuHeight={220}
                                        styles={{
                                            menu: (provided) => ({
                                                ...provided,
                                                zIndex: 99,
                                            }),
                                        }}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={2} sm={6} xs={6} className="datepicker_info" style={{ zIndex: 99 }}>
                                <FormGroup>
                                    <Label for='yearID'>Year</Label>
                                    <div id="text">
                                        <InputGroup onMouseLeave={handleMouseLeave}>
                                            <DatePicker
                                                id="startDate"
                                                selected={selectedyear}
                                                onChange={(date) => handelYear(date)}
                                                onKeyDown={(e) => e.preventDefault()}
                                                dateFormat="yyyy"
                                                minDate={new Date(Startingyear, 0, 1)}
                                                maxDate={new Date(endingyear, 11, 28)}
                                                showYearPicker
                                            />
                                            <InputGroupText
                                                onClick={() => handleDateClick("startDate")}
                                            >
                                                <img src={Calendar} alt='' />
                                            </InputGroupText>
                                        </InputGroup>
                                    </div>
                                </FormGroup>
                            </Col>
                            <Col md={2} sm={6} xs={6} className="datepicker_info" style={{ zIndex: 99, marginLeft: "0px" }} id="text1"
                            >
                                <FormGroup>
                                    <Label>Month</Label>
                                    <div id="text" >
                                        <InputGroup onMouseLeave={handleMouseLeave}>

                                            <DatePicker
                                                id="endDate"
                                                onKeyDown={(e) => e.preventDefault()}
                                                selected={selectedDate}
                                                onChange={(date) => formatMonth(date)}
                                                maxDate={maxmont}
                                                dateFormat="MMMM"
                                                autoComplete="off"
                                                showMonthYearPicker
                                                style={{ width: "150px" }}
                                            />
                                            <InputGroupText
                                                onClick={() => handleDateClick("endDate")}
                                            >
                                                <img onClick={() => cancelmonth()} src={CancleIcon} alt='' />
                                                &nbsp;&nbsp;
                                                <img src={Calendar} alt='' />
                                            </InputGroupText>
                                        </InputGroup>
                                    </div>
                                </FormGroup>
                            </Col>
                            <Col md="auto" className="pt-4 mt-1">
                                <Label>{""}</Label>
                                <Button
                                    color="primary"
                                    className="ms-2"
                                    onClick={handleSubmit}
                                    disabled={orgvalue}
                                >
                                    Search
                                </Button>
                                <Button
                                    color="primary"
                                    className="ms-2"
                                    onClick={() => reset()}
                                >
                                    Reset
                                </Button>
                            </Col>
                        </Row>
                    </div>
                );
            }}
        ></Formik>
    );
};

export default SearchContainer;