import React, { useState } from 'react';
import { CardBody, Container, Input, Row, Col, Card } from 'reactstrap';
import ErrorBoundary from 'components/ErrorBoundary';
import PatmentActivity from 'components/PaymentActivity';
import PharmacyDetails from 'components/PharmacyDetails';
import CapturedActivity from 'components/CapturedActivity';
import ReplenishedActivity from 'components/ReplenishedActivity';
import { onExport } from "utils/helper";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

let initialFilters = {
    currentPage: 1,
    pageSize: 10,
    orderBy: 'modifiedDate',
    order: 'desc',
    filters: [],
};

const PaymentReconciliation = (props) => {

    const {
        loading,
        apiFilter,
        totalSize,
        handleSearchPaymentActivityColumnFilters,
        handlePaymentActivityPageChange,
        paymenttabledata,
        fetchPaymentActivityList,
        handleSearchPharmacyDetailsColumnFilters,
        handlePharmacyDetailsPageChange,
        pharmacytabledata,
        fetchPharmacyDetailsList,
        handleSearchCapturedActivityColumnFilters,
        handleCapturedPageChange,
        capturedtabledata,
        fetchCapturedActivityList,
        handleSearchReplenishedActivityColumnFilters,
        handleReplenishedPageChange,
        replenishedtabledata,
        fetchReplenishedActivityList,
        CoveredEntity,
        opaCode,
        Cycle,
        TotalPaymentReceived,
        PendingAmount,
        payloaddata,
        payActrefetch,
        phaActrefetch,
        CapActrefetch,
        RepActrefetch
    } = props;

    const [key, setkey] = useState('paymentactivityTab');
    const [reset, setReset] = useState(false);

    const onKeyChange = (k) => {

        if (k === "paymentactivityTab") {
            fetchPaymentActivityList(initialFilters)
        }
        else {
            fetchPharmacyDetailsList(initialFilters);
            fetchCapturedActivityList(initialFilters);
            fetchReplenishedActivityList(initialFilters);
        }
        setkey(k);
        setReset(!reset);
    };

    const [activeTab, setActiveTab] = useState(1);

    const toggle = (tab) => {
        if (activeTab !== tab) {
            setActiveTab(tab);
        };
    };

    const [commentDrawer, setCommentDrawer] = useState(false);

    const toggCommentDrawer = (row) => {
        setCommentDrawer(!commentDrawer);
    };

    const handleExportPaymentActivity = async (row) => {

        let filter = { ...payloaddata, ...initialFilters };
        const { id_token } = JSON.parse(localStorage.getItem('pharmaforce')) || {};
        const action = `${process.env.REACT_APP_ENDPOINT}/${process.env.REACT_APP_ENDPOINT_POSTFIX}/PaymentActivity/export?access_token=${id_token}`;
        onExport(action, filter);
    };

    const handleExport = async () => {
        let filter = { ...payloaddata, ...initialFilters };

        const { id_token } = JSON.parse(localStorage.getItem('pharmaforce')) || {};
        var action
        if (activeTab == 1) {
            action = `${process.env.REACT_APP_ENDPOINT}/${process.env.REACT_APP_ENDPOINT_POSTFIX}/PharmacyActivity/export?access_token=${id_token}`;
        }
        else if (activeTab == 2) {
            action = `${process.env.REACT_APP_ENDPOINT}/${process.env.REACT_APP_ENDPOINT_POSTFIX}/CapturedActivity/export?access_token=${id_token}`;
        }
        else {
            action = `${process.env.REACT_APP_ENDPOINT}/${process.env.REACT_APP_ENDPOINT_POSTFIX}/ReplenishedActivity/export?access_token=${id_token}`;
        }
        onExport(action, filter);
    };

    return (
        <div>
            <Container fluid className=' my-0 bin_info'>
                <Card className=''>
                    <CardBody>
                        <Tabs
                            id="controlled-tab-example"
                            activeKey={key}
                            onSelect={onKeyChange}
                            className="mb-0"
                        >
                            <Tab eventKey="paymentactivityTab" title="Payment Activity">
                                <ErrorBoundary>
                                    <PatmentActivity
                                        toggCommentDrawer={toggCommentDrawer}
                                        loading={loading}
                                        tabledata={paymenttabledata}
                                        apiFilter={apiFilter}
                                        totalSize={totalSize}
                                        onPageChange={handlePaymentActivityPageChange}
                                        refetch={payActrefetch}
                                        onSearchColumnFilters={handleSearchPaymentActivityColumnFilters}
                                        handleExport={handleExportPaymentActivity}
                                        CoveredEntity={CoveredEntity}
                                        opaCode={opaCode}
                                        Cycle={Cycle}
                                        TotalPaymentReceived={TotalPaymentReceived}
                                        PendingAmount={PendingAmount}
                                    />
                                </ErrorBoundary>
                            </Tab>
                            <Tab eventKey="pharmacydetailsTab" title="Pharmacy Activity">
                                <ErrorBoundary>
                                    <Row>
                                        <Col md={4}>
                                            <div style={{ display: "flex", justifyContent: "center" }}>
                                                <Input type='radio' checked={activeTab == 1}
                                                    onClick={(e) => {
                                                        toggle(1);
                                                    }}
                                                /><b style={{ color: '#702c62', marginLeft: "5px" }}>Pharmacy Level Fees</b>
                                            </div>
                                        </Col>
                                        <Col md={4}>
                                            <div style={{ display: "flex", justifyContent: "center" }}>
                                                <Input type='radio' checked={activeTab == 2} onClick={(e) => {
                                                    toggle(2);
                                                }} /> <b style={{ color: '#702c62', marginLeft: "5px" }}>Captured Activity</b>
                                            </div>
                                        </Col>
                                        <Col md={4}>
                                            <div style={{ display: "flex", justifyContent: "center" }}>
                                                <Input type='radio' checked={activeTab == 3} onClick={(e) => {
                                                    toggle(3);
                                                }} /><b style={{ color: '#702c62', marginLeft: "5px" }}>Replenished Activity</b>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className='align-items-center' style={{ height: "55px" }}>
                                        <Col md='auto' className='pe-3'>
                                            <p className='f-14 mb-0'>
                                                Covered Entity:
                                                <b className='d-inline-block ps-2 font-weight-500'>
                                                    <span>{CoveredEntity}</span>
                                                </b>
                                            </p>
                                        </Col>
                                        <Col md='auto' className='pe-3'>
                                            <p className='f-14 mb-0'>
                                                340B ID:
                                                <b className='d-inline-block ps-2 font-weight-500'>
                                                    <span>{opaCode}</span>
                                                </b>
                                            </p>
                                        </Col>
                                        <Col md='auto' className='pe-3'>
                                            <p className='f-14 mb-0'>
                                                Cycle:
                                                <b className='d-inline-block ps-2 font-weight-500'>
                                                    <span>{Cycle}</span>
                                                </b>
                                            </p>
                                        </Col>
                                    </Row>
                                    <div hidden={activeTab != 1}>
                                        <PharmacyDetails
                                            toggCommentDrawer={toggCommentDrawer}
                                            loading={loading}
                                            tabledata={pharmacytabledata}
                                            apiFilter={apiFilter}
                                            totalSize={totalSize}
                                            onPageChange={handlePharmacyDetailsPageChange}
                                            refetch={phaActrefetch}
                                            onSearchColumnFilters={handleSearchPharmacyDetailsColumnFilters}
                                            handleExport={handleExport}
                                        />
                                    </div>
                                    <div hidden={activeTab != 2}>
                                        <CapturedActivity
                                            toggCommentDrawer={toggCommentDrawer}
                                            loading={loading}
                                            tabledata={capturedtabledata}
                                            apiFilter={apiFilter}
                                            totalSize={totalSize}
                                            onPageChange={handleCapturedPageChange}
                                            refetch={CapActrefetch}
                                            onSearchColumnFilters={handleSearchCapturedActivityColumnFilters}
                                            handleExport={handleExport}
                                        />
                                    </div>
                                    <div hidden={activeTab != 3}>
                                        <ReplenishedActivity
                                            toggCommentDrawer={toggCommentDrawer}
                                            loading={loading}
                                            tabledata={replenishedtabledata}
                                            apiFilter={apiFilter}
                                            totalSize={totalSize}
                                            onPageChange={handleReplenishedPageChange}
                                            refetch={RepActrefetch}
                                            onSearchColumnFilters={handleSearchReplenishedActivityColumnFilters}
                                            handleExport={handleExport}
                                        />
                                    </div>
                                </ErrorBoundary>
                            </Tab>
                        </Tabs>
                    </CardBody>
                </Card>
            </Container>
        </div>
    );
};

export default PaymentReconciliation;