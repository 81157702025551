import React, { useEffect, useState } from 'react';
import {
  Navbar,
  NavbarBrand,
  Nav,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import { useNavigate } from 'react-router-dom';

import Logo from 'components/Logo';
import poweredBy from 'assets/images/powered_by_pharmaforce.svg';
import useOidc from 'hooks/useOidc';

const Header = () => {
  const { logout } = useOidc();
  const [hidePoweredBy, setHidePoweredBy] = useState(false);
  const [userName, setUserName] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const { token_type, access_token, profile } =
      JSON.parse(sessionStorage.getItem('pharmaforce')) || {};
    const interval = setInterval(() => { }, 1000);
    const { username } = profile || {};
    setUserName(username);
    const theme = sessionStorage.getItem('theme') || "PF";

    if (theme === "PF") setHidePoweredBy(true);

    if (!access_token) {
      navigate('/');
    }
    return () => {
      clearInterval(interval);
    };
  }, []);

  const handleLogout = () => {
    sessionStorage.clear();
    logout();
  };

  return (
    <>
      <Navbar color='white' light className='py-1 app-header'>
        <div className='d-flex align-items-center'>
          <NavbarBrand href='/' className='me-0'>
            <Logo height={50} />
          </NavbarBrand>
          <h6 className='ps-3 text-primary d-inline mx-1 mt-1 mb-0 f-28 raleway-500'>
            Financial Management
          </h6>
        </div>
        <div className='ms-auto powered-by'>
          <img src={poweredBy} title='' alt='' hidden={hidePoweredBy} />
        </div>

        {(
          <Nav className='ps-4'>
            <UncontrolledDropdown nav className='profile-dropdown'>
              <DropdownToggle nav caret className='font-weight-500'>
                {/* {!isEmpty(data) ? data.firstName + ' ' + data.lastName : ''} */}
                {userName}
              </DropdownToggle>
              <DropdownMenu className='shadow'>
                <DropdownItem onClick={handleLogout}>Logout</DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        )}
      </Navbar>
    </>
  );
};

export default Header;