import React, { useEffect, useState } from 'react';
import ReactStrapTable from "components/reactStrapTable";
import { cellRendererCheck, currencyFormat, cellnumberFormat } from "utils/columns";
import Loader from 'components/Loader';

const CapturedActivity = (props) => {

    const {
        tabledata,
        totalSize,
        onPageChange,
        apiFilter,
        refetch,
        onSearchColumnFilters,
        handleExport,
        loading
    } = props;

    const [reset, setReset] = useState(false);
    const [filters, setFilters] = useState(apiFilter);

    useEffect(() => {
        setFilters(apiFilter);
    }, [apiFilter]);

    useEffect(() => {
        const { token_type, access_token, profile } =
            JSON.parse(sessionStorage.getItem('pharmaforce')) || {};
    }, []);

    useEffect(() => {
        setReset((pre) => !pre);
        setFilters({
            currentPage: 1,
            pageSize: 10,
            orderBy: '',
            order: '',
            filters: [],
        });
    }, []);

    const onHandleSort = (filter, hasColFilter) => {
        setFilters(filter);
        if (hasColFilter) {
            onPageChange(filter, true);
        } else {
            onPageChange(filter);
        };
    };

    const handleFilterCancel = () => {
        refetch(true);
    };

    const onPageSizeChange = (filter, hasColFilter, colChangedFilter) => {
        if (hasColFilter) {
            setFilters(filter);
            let colFilterOn = { ...filter, filters: colChangedFilter };
            return onPageChange(colFilterOn, true);
        };
        setFilters(filter);
        onPageChange(filter);
    };

    const onHandlePageChange = (filter, hasColFilter, colChangedFilter) => {
        if (hasColFilter) {
            setFilters(filter);
            let colFilterOn = { ...filter, filters: colChangedFilter };
            onPageChange(colFilterOn, true);
        } else {
            setFilters(filter);
            onPageChange(filter);
        };
    };

    const handleSearchColumnFilters = (colFilter) => {

        if (onSearchColumnFilters) {
            const filter = { ...filters, filters: colFilter, currentPage: 1 };
            onSearchColumnFilters(filter);
            setFilters(filter);
        };
    };

    let maincol = [
        {
            dataField: 'edit',
            text: 'Options',
            // cellRenderer: (row) => renderButtons(row),
            width: 80,
            show: true,
            disabled: true,
            hideColFilter: true,
            action: true,
        },
        {
            dataField: 'phName',
            text: 'Pharmacy',
            width: 170,
            show: true,
            sort: true,
            inputType: 'text',
            cellRenderer: (row) => cellRendererCheck(row.phName),
        },
        {
            dataField: 'capturedClaimCount',
            text: 'Captured Claim Count',
            width: 100,
            show: true,
            sort: true,
            inputType: 'number',
            cellRenderer: (row) => cellnumberFormat(row.capturedClaimCount),
        },
        {
            dataField: 'capturedTotalPaid',
            text: 'Captured Total Paid',
            width: 100,
            show: true,
            sort: true,
            inputType: 'number',
            hideColFilter: true,
            cellRenderer: (row) => currencyFormat(row.capturedTotalPaid),
        },
        {
            dataField: 'capturedDispensingFees',
            text: 'Captured Dispense Fees',
            width: 100,
            show: true,
            sort: true,
            inputType: 'number',
            hideColFilter: true,
            cellRenderer: (row) => currencyFormat(row.capturedDispensingFees),
        },
        {
            dataField: 'capturedCEReceivable',
            text: 'Captured CE Receivable',
            width: 100,
            sort: true,
            show: true,
            inputType: 'number',
            hideColFilter: true,
            cellRenderer: (row) => currencyFormat(row.capturedCEReceivable),
        },
        {
            dataField: 'capturedEst340BDrugCost',
            text: 'Captured 340B Drug Cost',
            width: 100,
            show: true,
            sort: true,
            inputType: 'number',
            hideColFilter: true,
            cellRenderer: (row) => currencyFormat(row.capturedEst340BDrugCost),
        },
        {
            dataField: 'captured340BSavings',
            text: 'Captured 340B Savings',
            width: 100,
            show: true,
            sort: true,
            inputType: 'number',
            hideColFilter: true,
            cellRenderer: (row) => currencyFormat(row.captured340BSavings),
        }
    ];

    let data = [];

    return (
        <div
            className='table-card scroll-table position-relative border-0  rounded pt-0'
            fixed-height={data.length > 0 ? 'fixed-height' : 'default'}>
            {loading && <Loader />}

            <ReactStrapTable
                id='capturedactivity'
                fixedColumn
                hasPagination
                data={tabledata}
                columns={maincol}
                filters={filters}
                totalRecords={totalSize}
                pageSize={filters.pageSize}
                currentPage={filters.currentPage}
                handleSort={onHandleSort}
                onPageSizeChange={onPageSizeChange}
                onPageChange={onHandlePageChange}
                reset={reset}
                onFilterCancel={handleFilterCancel}
                onSearchColumnFilters={handleSearchColumnFilters}
                onExport={handleExport}
            />
        </div>
    );
};

export default CapturedActivity;