import Oidc from 'oidc-client';
import useContextService from 'hooks/useContextService';

const oidcConfig = {
  authority: process.env.REACT_APP_OPENID_AUTHORITY,
  client_id: process.env.REACT_APP_OPENID_CLIENT_ID,
  redirect_uri: `${process.env.REACT_APP_OPENID_REDIRECT_URL}`,
  response_type: process.env.REACT_APP_OPENID_RESPONSE_TYPE,
  scope: process.env.REACT_APP_OPENID_SCOPE,
  post_logout_redirect_uri:
    process.env.REACT_APP_OPENID_POST_LOGOUT_REDIRECT_URI,
  filterProtocolClaims: true,
  loadUserInfo: true,
};

const { origin } = new URL(window.location.href);
const redirect_uri = origin + '/';
oidcConfig.redirect_uri = redirect_uri;
oidcConfig.post_logout_redirect_uri = redirect_uri;
oidcConfig.silent_redirect_uri = `${redirect_uri}silent-renew.html`;

const userManager = new Oidc.UserManager({
  ...oidcConfig,
  automaticSilentRenew: true,
  silentRequestTimeout: 10000,
  validateSubOnSilentRenew: false,
  includeIdTokenInSilentRenew: true,
  userStore: new Oidc.WebStorageStateStore({ store: window.sessionStorage }),
});

userManager.events.load((user) => {
  window.location.reload();
});

userManager.events.addUserUnloaded((user) => {
  console.log('addUserUnloaded ===>> ', user);
});

userManager.events.addSilentRenewError((user) => {
  console.log('addSilentRenewError ===>> ', user);
});

userManager.events.addAccessTokenExpired(async (e) => {
  console.log('addAccessTokenExpired ===>> ', e);
  sessionStorage.clear();
  window.location.href = '/';
});

userManager.events.addAccessTokenExpiring((user) => {
  console.log('addAccessTokenExpiring ===>> ', user);
  userManager.signinSilent();
});

const useOidc = () => {
  const { setStates } = useContextService();

  userManager.events.addUserLoaded((user) => {
    sessionStorage.setItem('pharmaforce', JSON.stringify(user));
    //setStates('userDetails', user);//CHECK: Needs to check if it is needed?
  });

  const signIn = async () => {
    userManager.signinRedirect();
  };

  const signinRedirectCallback = () => {
    userManager.signinRedirectCallback();
  };

  const getUser = async () => {
    const user = userManager.getUser();
    return user;
  };

  const signinSilent = async () => {
    userManager.signinSilent();
  };

  const logout = async () => {
    userManager.signoutRedirect();
  };

  return { signIn, getUser, signinRedirectCallback, logout, signinSilent };
};

export default useOidc;