import React from 'react';
import moment from 'moment';

export const cellRendererCheck = (value) => {

  if (!value) return '-';
  return value;
};

export const cellnumberFormat = (value) => {

  if (!value)
    return (
      '-'
    );
  else
    return (
      <span className='text-end'>
        {value.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
      </span>
    );
};

export const celldateFormate = (row, value) => {

  if (!value) return '-';
  if (row.file_c_status === "At Risk") {
    return (
      <span className='text-danger text-end'>
        {moment(new Date(value)).format('MM/DD/YYYY')}
      </span>
    );
  }
  else
    return (
      <span className='text-end'>
        {moment(new Date(value)).format('MM/DD/YYYY')}
      </span>
    );
};

export const currencyFormat = (value) => {

  if (!value || isNaN(value)) return '-';
  if (value < 0)
    return (
      <span className='text-end'>
        {'-$' +
          (value * -1).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
      </span>
    );
  else
    return (
      <span className='text-end'>
        {'$' + value.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
      </span>
    );
};

export const fileFormatter = (row, download) => {

  const { files } = row;
  if (files && files.length) {
    return files.map((file, index) => {
      return (
        <span
          className='fax-link'
          key={`${file.fileName}${index}`}
          onClick={() => download(file)}
        >
          {file.fileName}
          <br />
        </span>
      );
    });
  }
  return <span>There is no attachment</span>;
};

export const dateFormate = (value) => {

  if (!value || value === "0001-01-01T00:00:00") return '-';
  return moment(new Date(value)).format('MM/DD/YYYY');
};

export const dateTimeFormate = (value) => {

  if (!value) return '-';
  var dt = moment(new Date(value)).format('MM/DD/YYYY');
  var strDt = new Date(dt + ' UTC');
  return moment(new Date(strDt)).format('MM/DD/YYYY');
};