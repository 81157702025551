const onExport = async (action, value) => {
  let form = document.createElement('form');
  form.method = 'post';
  form.target = '_blank';
  form.action = action;
  form.innerHTML = `'<input type="text"  name="model"  value=${JSON.stringify(
    value
  )}>'`;
  document.body.appendChild(form);
  form.submit();
  document.body.removeChild(form);
};

const getUpdatedFilter = (filterName, filterValue, arry, hasDate) => {
  let fil = [...arry.filters];
  const index = fil.findIndex(
    (item) => item.filterName === filterName && filterName != 'dateofservice'
  );

  let hasDateFilterType = '';
  if (hasDate === 'startDate') {
    hasDateFilterType = 7;
  };
  if (hasDate === 'endDate') {
    hasDateFilterType = 6;
  };
  if (filterName == "rxNumber") {
    hasDateFilterType = 3;
  };

  if (index >= 0) {
    fil[index] = {
      filterName,
      filterValue,
      filterType: hasDateFilterType || 1,
    };
  } else {
    fil = [
      ...fil,
      ...[
        {
          filterName,
          filterValue,
          filterType: hasDateFilterType || 1,
        },
      ],
    ];
  };
  return fil;
};

const getUpdatedFilterLatest = (filterName, filterValue, arry, hasDate) => {
  let fil = [...arry];
  const index = fil.findIndex(
    (item) => item.filterName === filterName && filterName != 'dateofservice'
  );

  let hasDateFilterType = '';
  if (hasDate === 'startDate') {
    hasDateFilterType = 7;
  };
  if (hasDate === 'endDate') {
    hasDateFilterType = 6;
  };
  if (filterName == "rxNumber") {
    hasDateFilterType = 3;
  };

  if (index >= 0 && !filterValue) {
    fil.splice(index, 1);
  } else if (index >= 0) {
    fil[index] = {
      filterName,
      filterValue,
      filterType: hasDateFilterType || 1,
    };
  } else {
    fil = [
      ...fil,
      ...[
        {
          filterName,
          filterValue,
          filterType: hasDateFilterType || 1,
        },
      ],
    ];
  };
  return fil;
};

export { onExport, getUpdatedFilter, getUpdatedFilterLatest };