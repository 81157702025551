import React from "react";
import { Offcanvas, OffcanvasHeader, OffcanvasBody } from "reactstrap";

const Drawer = ({
  isOpen,
  toggle,
  direction,
  children,
  title,
  size = "md",
}) => {
  return (
    <Offcanvas
      isOpen={isOpen}
      toggle={toggle}
      direction={direction}
      className={"common-sidebar bg-light " + size}
    >
      <OffcanvasHeader className="bg-dark" toggle={toggle}>
        {title}
      </OffcanvasHeader>
      <OffcanvasBody className="border-top">{children}</OffcanvasBody>
    </Offcanvas>
  );
};

export default Drawer;