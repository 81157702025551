import React, { useEffect, useState } from "react";
import { Container, Card, CardBody } from "reactstrap";
import ErrorBoundary from "components/ErrorBoundary";
import InvoiceTable from "./InvoiceTable";
import SearchContainer from "./SearchContainer";
import useAxios from 'hooks/useAxios';
import { onExport } from "utils/helper";
import Drawer from "components/Drawer";
import PaymentReconciliation from "components/PaymentReconciliation";
import { currencyFormat } from "utils/columns";

let initialFilters = {
    currentPage: 1,
    pageSize: 10,
    orderBy: 'modifiedDate',
    order: 'desc',
    filters: [],
};

let payloaddata;
let payloaddatas = {};
let CoveredEntity;
let opaCode;
let Cycle;
let TotalPaymentReceived;
let PendingAmount;
let ceid;
let monthrow;
let yearrow;
let handleFilterReset = 0;

const Invoices = (props) => {

    const API = useAxios();
    const [loading, setLoading] = useState(false);
    const [invoicetabledata, setInvoiceTabledata] = useState([]);
    const [paymenttabledata, setPaymentTabledata] = useState([]);
    const [pharmacytabledata, setPharmacyTabledata] = useState([]);
    const [capturedtabledata, setCapturedTabledata] = useState([]);
    const [replenishedtabledata, setReplenishedTabledata] = useState([]);
    const [totalItem, setTotalItem] = useState(0);
    const [commentDrawer, setCommentDrawer] = useState(false);
    const [coverentityid, setcoverentityid] = useState([]);

    const handleSearchInvoiceColumnFilters = async (columnFilters) => {
        await fetchInvoiceList(columnFilters, true);
    };

    const handleSearchPaymentActivityColumnFilters = async (columnFilters) => {
        await fetchPaymentActivityList(columnFilters, true);
    };

    const handleSearchPharmacyDetailsColumnFilters = async (columnFilters) => {
        await fetchPharmacyDetailsList(columnFilters, true);
    };

    const handleSearchCapturedActivityColumnFilters = async (columnFilters) => {
        await fetchCapturedActivityList(columnFilters, true);
    };

    const handleSearchReplenishedActivityColumnFilters = async (columnFilters) => {
        await fetchReplenishedActivityList(columnFilters, true);
    };

    const handlePageChange = (pageDetails, hasFilter) => {
        if (hasFilter) {
            fetchInvoiceList(pageDetails, true);
        } else {
            fetchInvoiceList(pageDetails);
        };
    };

    const handlePaymentActivityPageChange = (pageDetails, hasFilter) => {
        if (hasFilter) {
            fetchPaymentActivityList(pageDetails, true);
        } else {
            fetchPaymentActivityList(pageDetails);
        };
    };

    const handlePharmacyDetailsPageChange = (pageDetails, hasFilter) => {
        if (hasFilter) {
            fetchPharmacyDetailsList(pageDetails, true);
        } else {
            fetchPharmacyDetailsList(pageDetails);
        };
    };

    const handleCapturedPageChange = (pageDetails, hasFilter) => {
        if (hasFilter) {
            fetchCapturedActivityList(pageDetails, true);
        } else {
            fetchCapturedActivityList(pageDetails);
        };
    };

    const handleReplenishedPageChange = (pageDetails, hasFilter) => {
        if (hasFilter) {
            fetchReplenishedActivityList(pageDetails, true);
        } else {
            fetchReplenishedActivityList(pageDetails);
        };
    };

    const toggCommentDrawerActPay = (row) => {

        let initialFilter = {
            currentPage: 1,
            pageSize: 10,
            orderBy: 'modifiedDate',
            order: 'desc',
            filters: [],
        };
        ceid = row.ceid
        monthrow = row.month;
        yearrow = row.year;
        CoveredEntity = row.ceName
        opaCode = row.opaCode
        Cycle = row.monthYear
        TotalPaymentReceived = currencyFormat(row.pharmacyPaid);
        PendingAmount = currencyFormat(row.pendingAmount);
        setCommentDrawer(!commentDrawer);
        fetchPaymentActivityList(initialFilter);
        payloaddata.ceid = coverentityid;
    };

    const toggCommentDraweract = () => {

        let initialFilter = {
            currentPage: 1,
            pageSize: 10,
            orderBy: 'modifiedDate',
            order: 'desc',
            filters: [],
        };
        setCommentDrawer(!commentDrawer);
        fetchInvoiceList(initialFilter);

    };

    const resetdata = (hasReset) => {
        let initialFiltersdata = {
            currentPage: 1,
            pageSize: 10,
            orderBy: 'modifiedDate',
            order: 'desc',
            filters: [],
        };
        payloaddata = {};
        payloaddata.invoicetype = "0";
        payloaddata.organizationID = 0;
        payloaddata.year = 0;
        payloaddata.month = 1;
        payloaddata.ceid = ["0"];
        fetchInvoiceList(initialFiltersdata, undefined);
        const min = 1;
        const max = 10000;
        handleFilterReset = min + Math.random() * (max - min);
    };

    const refetch = (hasReset) => {
        if (hasReset) {
            initialFilters = {
                ...initialFilters,
                currentPage: 1,
                order: 'desc',
                orderBy: 'modifiedDate',
            };
        };
        fetchInvoiceList(initialFilters, undefined);
    };

    const payActrefetch = (hasReset) => {
        if (hasReset) {
            initialFilters = {
                ...initialFilters,
                currentPage: 1,
                order: 'desc',
                orderBy: 'modifiedDate',
            };
        };
        fetchPaymentActivityList(initialFilters, undefined);
    };

    const phaActrefetch = (hasReset) => {
        if (hasReset) {
            initialFilters = {
                ...initialFilters,
                currentPage: 1,
                order: 'desc',
                orderBy: 'modifiedDate',
            };
        };
        fetchPharmacyDetailsList(initialFilters, undefined);
    };

    const CapActrefetch = (hasReset) => {
        if (hasReset) {
            initialFilters = {
                ...initialFilters,
                currentPage: 1,
                order: 'desc',
                orderBy: 'modifiedDate',
            };
        };
        fetchCapturedActivityList(initialFilters, undefined);
    };

    const RepActrefetch = (hasReset) => {
        if (hasReset) {
            initialFilters = {
                ...initialFilters,
                currentPage: 1,
                order: 'desc',
                orderBy: 'modifiedDate',
            };
        };
        fetchReplenishedActivityList(initialFilters, undefined);
    };

    const handleExport = async () => {

        let filter = { ...payloaddata, ...initialFilters };
        const { access_token } = JSON.parse(sessionStorage.getItem('pharmaforce')) || {};
        const action = `${process.env.REACT_APP_ENDPOINT}/${process.env.REACT_APP_ENDPOINT_POSTFIX}/CEFMDetails/cefmdetailexportall?access_token=${access_token}`;
        onExport(action, filter);
    };

    const download = async (e) => {

        const { access_token } = JSON.parse(sessionStorage.getItem('pharmaforce')) || {};
        const action = `${process.env.REACT_APP_ENDPOINT}/${process.env.REACT_APP_ENDPOINT_POSTFIX}/CEFMDetails/DowloadExportInvoiceData/${e.ceid}/${e.month}/${e.year}?access_token=${access_token}`;
        onExport(action);
    };

    const cleartabledata = async () => {
        var data = '';
        setInvoiceTabledata(data);
    };

    const onSearch = async (e) => {

        let initialFilter = {
            currentPage: 1,
            pageSize: 10,
            orderBy: 'modifiedDate',
            order: 'desc',
            filters: [],
        };
        payloaddata = {};
        payloaddata.invoicetype = e.invoicetype
        payloaddata.organizationID = e.organizationID
        payloaddata.year = e.year
        payloaddata.month = e.month
        payloaddata.ceid = e.optCe == undefined ? [-1] : e.optCe;
        setcoverentityid(payloaddata.ceid);
        fetchInvoiceList(initialFilter, undefined);
    };

    const fetchInvoiceList = async (pageDetails = {}, hasColumnFilter) => {

        let filter = { ...pageDetails, ...payloaddata };
        if (hasColumnFilter) {
            filter.filters = [...initialFilters.filters, ...pageDetails.filters];
            initialFilters.filters = [...initialFilters.filters];
        } else {
            initialFilters = { ...initialFilters, ...pageDetails };
        };

        setLoading(true);
        try {
            const { data } = await API.post(
                `/CEFMDetails/gridgetcefmdetailslist`,
                filter,
            );
            if (data) {

                setInvoiceTabledata(data.items);
                setTotalItem(data.totalRecords);
            } else {
                setData([]);
                setTotalItem(0);
            };

            setLoading(false);
        } catch (error) {
            setLoading(false);
            setInvoiceTabledata([]);
            setTotalItem(0);
        };
    };

    const fetchpayloaddatas = async (pageDetails = {}, hasColumnFilter) => {
        payloaddatas.ceid = [ceid];
        payloaddatas.month = monthrow;
        payloaddatas.year = yearrow;
        payloaddatas.invoicetype = 1;
        payloaddatas.organizationID = 0;
    };

    const fetchPaymentActivityList = async (pageDetails = {}, hasColumnFilter) => {

        payloaddatas.ceid = [ceid];
        payloaddatas.month = monthrow;
        payloaddatas.year = yearrow;
        payloaddatas.invoicetype = 1;
        payloaddatas.organizationID = 0;
        let filter = { ...pageDetails, ...payloaddatas };
        if (hasColumnFilter) {
            filter.filters = [...initialFilters.filters, ...pageDetails.filters];
            initialFilters.filters = [...initialFilters.filters];
        } else {
            initialFilters = { ...initialFilters, ...pageDetails };
        };

        setLoading(true);
        try {
            const { data } = await API.post(
                `/paymentActivity/gridgetpaymentlist`,
                filter,
            );

            if (data) {
                setPaymentTabledata(data.items);
                setTotalItem(data.totalRecords);
                fetchpayloaddatas();
            } else {
                setData([]);
                setTotalItem(0);
            };

            setLoading(false);
        } catch (error) {
            setLoading(false);
            setPaymentTabledata([]);
            setTotalItem(0);
        };
    };

    const fetchPharmacyDetailsList = async (pageDetails = {}, hasColumnFilter) => {

        payloaddatas.ceid = [ceid];
        payloaddatas.month = monthrow;
        payloaddatas.year = yearrow;
        payloaddatas.invoicetype = 1;
        payloaddatas.organizationID = 0;
        let filter = { ...pageDetails, ...payloaddatas };
        if (hasColumnFilter) {
            filter.filters = [...initialFilters.filters, ...pageDetails.filters];
            initialFilters.filters = [...initialFilters.filters];
        } else {
            initialFilters = { ...initialFilters, ...pageDetails };
        };

        setLoading(true);
        try {
            const { data } = await API.post(
                `/pharmacyActivity/gridgetpharmacylist`,
                filter,
            );

            if (data) {
                setPharmacyTabledata(data.items);
                setTotalItem(data.totalRecords);
            } else {
                setData([]);
                setTotalItem(0);
            };

            setLoading(false);
        } catch (error) {
            setLoading(false);
            setPharmacyTabledata([]);
            setTotalItem(0);
        };
    };

    const fetchCapturedActivityList = async (pageDetails = {}, hasColumnFilter) => {

        payloaddatas.ceid = [ceid];
        payloaddatas.month = monthrow;
        payloaddatas.year = yearrow;
        payloaddatas.invoicetype = 1;
        payloaddatas.organizationID = 0;
        let filter = { ...pageDetails, ...payloaddatas };
        if (hasColumnFilter) {
            filter.filters = [...initialFilters.filters, ...pageDetails.filters];
            initialFilters.filters = [...initialFilters.filters];
        } else {
            initialFilters = { ...initialFilters, ...pageDetails };
        };

        setLoading(true);
        try {
            const { data } = await API.post(
                `/capturedActivity/gridgetcapturedlist`,
                filter,
            );

            if (data) {
                setCapturedTabledata(data.items);
                setTotalItem(data.totalRecords);
            } else {
                setData([]);
                setTotalItem(0);
            };

            setLoading(false);
        } catch (error) {
            setLoading(false);
            setCapturedTabledata([]);
            setTotalItem(0);
        };
    };

    const fetchReplenishedActivityList = async (pageDetails = {}, hasColumnFilter) => {

        payloaddatas.ceid = [ceid];
        payloaddatas.month = monthrow;
        payloaddatas.year = yearrow;
        payloaddatas.invoicetype = 1;
        payloaddatas.organizationID = 0;
        let filter = { ...pageDetails, ...payloaddatas };
        if (hasColumnFilter) {
            filter.filters = [...initialFilters.filters, ...pageDetails.filters];
            initialFilters.filters = [...initialFilters.filters];
        } else {
            initialFilters = { ...initialFilters, ...pageDetails };
        };

        setLoading(true);
        try {
            const { data } = await API.post(
                `/replenishedActivity/gridgetreplenishedlist`,
                filter,
            );

            if (data) {
                setReplenishedTabledata(data.items);
                setTotalItem(data.totalRecords);
            } else {
                setData([]);
                setTotalItem(0);
            };

            setLoading(false);
        } catch (error) {
            setLoading(false);
            setReplenishedTabledata([]);
            setTotalItem(0);
        };
    };

    return (
        <div>
            <Container fluid className='my-3'>
                <Card className='border-0 shadow-top'>
                    <CardBody className='section-devider-border'>
                        <ErrorBoundary>
                            <SearchContainer
                                toggCommentDrawer={toggCommentDrawerActPay}
                                onSearch={onSearch}
                                cleartabledata={cleartabledata}
                                onReset={resetdata}
                            />
                        </ErrorBoundary>
                    </CardBody>
                    <CardBody className='pt-2'>
                        <ErrorBoundary>
                            <InvoiceTable
                                toggCommentDrawer={toggCommentDrawerActPay}
                                loading={loading}
                                tabledata={invoicetabledata}
                                apiFilter={initialFilters}
                                totalSize={totalItem}
                                onPageChange={handlePageChange}
                                refetch={refetch}
                                onSearchColumnFilters={handleSearchInvoiceColumnFilters}
                                fetchPaymentActivityList={fetchPaymentActivityList}
                                handleExport={handleExport}
                                download={download}
                                handleFilterReset={handleFilterReset}

                            />
                        </ErrorBoundary>
                    </CardBody>
                </Card>
                <Drawer
                    isOpen={commentDrawer}
                    direction='end'
                    title='Payment Reconciliation'
                    size="lg"

                    toggle={toggCommentDraweract}
                >
                    <PaymentReconciliation
                        toggCommentDrawer={toggCommentDrawerActPay}
                        loading={loading}
                        apiFilter={initialFilters}
                        totalSize={totalItem}
                        payActrefetch={payActrefetch}
                        phaActrefetch={phaActrefetch}
                        CapActrefetch={CapActrefetch}
                        RepActrefetch={RepActrefetch}
                        handleExport={handleExport}
                        handleSearchPaymentActivityColumnFilters={handleSearchPaymentActivityColumnFilters}
                        handlePaymentActivityPageChange={handlePaymentActivityPageChange}
                        paymenttabledata={paymenttabledata}
                        fetchPaymentActivityList={fetchPaymentActivityList}
                        handleSearchPharmacyDetailsColumnFilters={handleSearchPharmacyDetailsColumnFilters}
                        handlePharmacyDetailsPageChange={handlePharmacyDetailsPageChange}
                        pharmacytabledata={pharmacytabledata}
                        fetchPharmacyDetailsList={fetchPharmacyDetailsList}
                        handleSearchCapturedActivityColumnFilters={handleSearchCapturedActivityColumnFilters}
                        handleCapturedPageChange={handleCapturedPageChange}
                        capturedtabledata={capturedtabledata}
                        fetchCapturedActivityList={fetchCapturedActivityList}
                        handleSearchReplenishedActivityColumnFilters={handleSearchReplenishedActivityColumnFilters}
                        handleReplenishedPageChange={handleReplenishedPageChange}
                        replenishedtabledata={replenishedtabledata}
                        fetchReplenishedActivityList={fetchReplenishedActivityList}
                        CoveredEntity={CoveredEntity}
                        payloaddata={payloaddatas}
                        opaCode={opaCode}
                        Cycle={Cycle}
                        TotalPaymentReceived={TotalPaymentReceived}
                        PendingAmount={PendingAmount}
                    />
                </Drawer>
            </Container>
        </div>
    );
};

export default Invoices;