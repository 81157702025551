import React, { useEffect, useState } from 'react';
import { Button } from "reactstrap";
import ReactStrapTable from "components/reactStrapTable";
import EyeIcon from 'assets/icon/icon-icon-options-view-patient.svg';
import EyeIconHover from 'assets/icon/icon-icon-options-view-patient_hover.svg';
import DownloadIcon from 'assets/icon/download.svg';
import DownloadIconHover from 'assets/icon/download_hover.svg';
import HoverImage from "react-hover-image/build";
import { cellRendererCheck, currencyFormat, cellnumberFormat } from "utils/columns";
import Tooltip from 'react-tooltip-lite';
import FileIcon from 'assets/icon/file_icon.svg';
import FileHoverIcon from 'assets/icon/file_icon-hover.svg';

const InvoiceTable = (props) => {

    const {
        tabledata,
        totalSize,
        onPageChange,
        apiFilter,
        refetch,
        onSearchColumnFilters,
        handleExport,
        toggCommentDrawer,
        download,
        handleFilterReset,
        onInvoiceNotes
    } = props;

    const [reset, setReset] = useState(false);
    const [filters, setFilters] = useState(apiFilter);

    useEffect(() => {
        setFilters(apiFilter);
    }, [apiFilter]);

    useEffect(() => {
        setReset((pre) => !pre);
        setFilters({
            currentPage: 1,
            pageSize: 10,
            orderBy: '',
            order: '',
            filters: [],
        });
    }, []);

    const onHandleSort = (filter, hasColFilter) => {
        setFilters(filter);
        if (hasColFilter) {
            onPageChange(filter, true);
        } else {
            onPageChange(filter);
        };
    };

    const handleFilterCancel = () => {
        refetch(true);
    };

    const onPageSizeChange = (filter, hasColFilter, colChangedFilter) => {
        if (hasColFilter) {
            setFilters(filter);
            let colFilterOn = { ...filter, filters: colChangedFilter };
            return onPageChange(colFilterOn, true);
        };
        setFilters(filter);
        onPageChange(filter);
    };

    const onHandlePageChange = (filter, hasColFilter, colChangedFilter) => {
        if (hasColFilter) {
            setFilters(filter);
            let colFilterOn = { ...filter, filters: colChangedFilter };
            onPageChange(colFilterOn, true);
        } else {
            setFilters(filter);
            onPageChange(filter);
        };
    };

    const handleSearchColumnFilters = (colFilter) => {

        if (onSearchColumnFilters) {
            const filter = { ...filters, filters: colFilter, currentPage: 1 };
            onSearchColumnFilters(filter);
            setFilters(filter);
        };
    };

    const cellrendertooltip = (row, value) => {

        if (row.carryOver === 0 && row.carryOverNotes != null) {
            return (
                <Tooltip content={(
                    <div className='tip-list-info'>
                        <p>{row.carryOverNotes}</p>
                    </div>
                )}
                    direction="down"
                    tagName="span"
                    className=""
                    style={{
                        display: "flex",
                        flex: "wrap"
                    }}
                    tipContentClassName=""
                    zIndex={9999}>
                    <span
                        className='cursor-pointer text-decoration-underline'
                    >
                        {currencyFormat(row.carryOver)}
                    </span>
                </Tooltip>)
        }
        else if (row.carryOver != 0 && row.carryOverNotes == null) {
            return (
                currencyFormat(row.carryOver)
            )
        }
        else if (row.carryOver != 0 && row.carryOverNotes != null) {
            return (
                <Tooltip content={(
                    <div className='tip-list-info'>
                        <p>{row.carryOverNotes}</p>
                    </div>
                )}
                    direction="down"
                    tagName="span"
                    className=""
                    style={{
                        display: "flex",
                        flex: "wrap"
                    }}
                    tipContentClassName=""
                    zIndex={9999}>
                    <span
                        className='cursor-pointer text-decoration-underline'
                    >
                        {currencyFormat(row.carryOver)}
                    </span>
                </Tooltip>)
        }
        else {
            if (!value) return '-';
            return value;
        };
    };

    const cellrendertooltipalternatefee = (row, value) => {

        if (row.ceAdjustments === 0 && row.flatFeeNotes != null)  {
            return (
                <Tooltip content={(
                    <div className='tip-list-info'>
                        <p>{row.flatFeeNotes}</p>
                    </div>
                )}
                    direction="down"
                    tagName="span"
                    className=""
                    style={{
                        display: "flex",
                        flex: "wrap"
                    }}
                    tipContentClassName=""
                    zIndex={9999}>
                    <span
                        className='cursor-pointer text-decoration-underline'
                    >
                        {currencyFormat(row.ceAdjustments)}
                    </span>
                </Tooltip>)
        }
        else if (row.ceAdjustments != 0 && row.flatFeeNotes == null) {
            return (
                currencyFormat(row.ceAdjustments)
            )
        }
        else if (row.ceAdjustments != 0 && row.flatFeeNotes != null) {
            return (
                <Tooltip content={(
                    <div className='tip-list-info'>
                        <p>{row.flatFeeNotes}</p>
                    </div>
                )}
                    direction="down"
                    tagName="span"
                    className=""
                    style={{
                        display: "flex",
                        flex: "wrap"
                    }}
                    tipContentClassName=""
                    zIndex={9999}>
                    <span
                        className='cursor-pointer text-decoration-underline'
                    >
                        {currencyFormat(row.ceAdjustments)}
                    </span>
                </Tooltip>)
        }
        else {
            if (!value) return '-';
            return value;
        };
    };

    const cellrendertooltipcashcardfee = (row, value) => {

        if (row.cashCardFees === 0 && row.cashCardFeeNotes != null) {
            return (
                <Tooltip content={(
                    <div className='tip-list-info'>
                        <p>{row.cashCardFeeNotes}</p>
                    </div>
                )}
                    direction="down"
                    tagName="span"
                    className=""
                    style={{
                        display: "flex",
                        flex: "wrap"
                    }}
                    tipContentClassName=""
                    zIndex={9999}>
                    <span
                        className='cursor-pointer text-decoration-underline'
                    >
                        {currencyFormat(row.cashCardFees)}
                    </span>
                </Tooltip>)
        }
        else if (row.cashCardFees != 0 && row.cashCardFeeNotes == null) {
            return (
                currencyFormat(row.cashCardFees)
            )
        }
        else if (row.cashCardFees != 0 && row.cashCardFeeNotes != null) {
            return (
                <Tooltip content={(
                    <div className='tip-list-info'>
                        <p>{row.cashCardFeeNotes}</p>
                    </div>
                )}
                    direction="down"
                    tagName="span"
                    className=""
                    style={{
                        display: "flex",
                        flex: "wrap"
                    }}
                    tipContentClassName=""
                    zIndex={9999}>
                    <span
                        className='cursor-pointer text-decoration-underline'
                    >
                        {currencyFormat(row.cashCardFees)}
                    </span>
                </Tooltip>)
        }
        else {
            if (!value) return '-';
            return value;
        };
    };

    const renderButtons = (row) => {
        return (
            <div>
                <Button
                    className="text-primary p-0 text-decoration-none"
                    color="link"
                    onClick={() => toggCommentDrawer(row)}
                    title="Click To View Payment Reconciliation"
                >
                    <span>
                        <HoverImage
                            key='EyeIcon'
                            src={EyeIcon}
                            hoverSrc={EyeIconHover}
                            alt=''
                        />
                    </span>
                </Button>
                <Button
                    className='text-primary p-0 text-decoration-none'
                    color='link'
                    onClick={() => download(row)}
                    title="Click To Download Payment Reconciliation"
                >
                    <span>
                        <HoverImage
                            key='DownloadIcon'
                            src={DownloadIcon}
                            hoverSrc={DownloadIconHover}
                            alt=''
                        />
                    </span>
                </Button>
                <Button
                    className="text-primary p-0 text-decoration-none"
                    color="link"
                    onClick={() => onInvoiceNotes(row)}
                    title="Click To View Notes"
                >
                    <span>
                        <HoverImage
                            key='FileIcon'
                            src={FileIcon}
                            hoverSrc={FileHoverIcon}
                            alt=''
                        />
                    </span>
                </Button>
            </div>
        );
    };

    let maincol = [
        {
            dataField: 'edit',
            text: 'Options',
            cellRenderer: (row) => renderButtons(row),
            width: 120,
            show: true,
            disabled: true,
            hideColFilter: true,
            action: true
        },
        {
            dataField: 'organizationName',
            text: 'Organization',
            width: 200,
            show: true,
            sort: true,
            inputType: 'text',
            cellRenderer: (row) => cellRendererCheck(row.organizationName)
        },
        {
            dataField: 'ceName',
            text: 'Covered Entity',
            width: 200,
            show: true,
            sort: true,
            inputType: 'text',
            cellRenderer: (row) => cellRendererCheck(row.ceName)
        },
        {
            dataField: 'opaCode',
            text: '340B ID',
            width: 120,
            show: true,
            sort: true,
            inputType: 'text',
            cellRenderer: (row) => cellRendererCheck(row.opaCode)
        },
        {
            dataField: 'monthYear',
            text: 'Month Year',
            width: 100,
            show: true,
            sort: true,
            inputType: 'text',
            cellRenderer: (row) => cellRendererCheck(row.monthYear)
        },
        {
            dataField: 'totalClaims',
            text: 'Total Claims Received',
            width: 100,
            sort: true,
            show: true,
            inputType: 'number',
            cellRenderer: (row) => cellnumberFormat(row.totalClaims)
        },
        {
            dataField: 'capturedClaims',
            text: 'Captured Claims Count',
            width: 100,
            show: true,
            sort: true,
            inputType: 'number',
            cellRenderer: (row) => cellnumberFormat(row.capturedClaims)
        },
        {
            dataField: 'pharmacyPaid',
            text: 'Total Payments Received',
            width: 100,
            show: true,
            sort: true,
            inputType: 'number',
            hideColFilter: true,
            style_textAlign: { textAlign: "right" },
            cellRenderer: (row) => currencyFormat(row.pharmacyPaid)
        },
        {
            dataField: 'minPFFee',
            text: 'PharmaForce TPA Fee',
            width: 110,
            show: true,
            sort: true,
            inputType: 'number',
            hideColFilter: true,
            style_textAlign: { textAlign: "right" },
            cellRenderer: (row) => currencyFormat(row.minPFFee)
        },
        {
            dataField: 'switchFee',
            text: 'Claim Processing',
            width: 100,
            show: true,
            sort: true,
            inputType: 'number',
            hideColFilter: true,
            style_textAlign: { textAlign: "right" },
            cellRenderer: (row) => currencyFormat(row.switchFee)
        },
        {
            dataField: 'ftFee',
            text: 'Transaction Charge',
            width: 100,
            show: true,
            sort: true,
            inputType: 'number',
            hideColFilter: true,
            style_textAlign: { textAlign: "right" },
            cellRenderer: (row) => currencyFormat(row.ftFee)
        },
        {
            dataField: 'gatewayFee',
            text: 'Gateway Charge',
            width: 100,
            show: true,
            sort: true,
            inputType: 'number',
            hideColFilter: true,
            style_textAlign: { textAlign: "right" },
            cellRenderer: (row) => currencyFormat(row.gatewayFee)
        },
        {
            dataField: 'pharmaForceAdjustment',
            text: 'Pharmacy Adjustments',
            width: 110,
            show: true,
            sort: true,
            inputType: 'number',
            hideColFilter: true,
            style_textAlign: { textAlign: "right" },
            cellRenderer: (row) => currencyFormat(row.pharmaForceAdjustment)
        },
        {
            dataField: 'cashCardFees',
            text: 'Cashcard Fee',
            width: 100,
            show: true,
            sort: true,
            inputType: 'number',
            hideColFilter: true,
            style_textAlign: { textAlign: "right" },
            cellRenderer: (row) => cellrendertooltipcashcardfee(row)
        },
        {
            dataField: 'historicalSwitchFees',
            text: 'Historical Switch Fees',
            width: 100,
            show: true,
            sort: true,
            inputType: 'number',
            hideColFilter: true,
            style_textAlign: { textAlign: "right" },
            cellRenderer: (row) => currencyFormat(row.historicalSwitchFees)
        },
        {
            dataField: 'referralCaptureServiceFees',
            text: 'Referral Capture Service',
            width: 100,
            show: true,
            sort: true,
            inputType: 'number',
            hideColFilter: true,
            style_textAlign: { textAlign: "right" },
            cellRenderer: (row) => currencyFormat(row.referralCaptureServiceFees)
        },
        {
            dataField: 'splitBillingAdminFees',
            text: 'Split Billing TPA Fee',
            width: 100,
            show: true,
            sort: true,
            inputType: 'number',
            hideColFilter: true,
            style_textAlign: { textAlign: "right" },
            cellRenderer: (row) => currencyFormat(row.splitBillingAdminFees)
        },
        {
            dataField: 'ceAdjustments',
            text: 'CE Adjustments',
            width: 100,
            show: true,
            sort: true,
            inputType: 'number',
            hideColFilter: true,
            style_textAlign: { textAlign: "right" },
            cellRenderer: (row) => cellrendertooltipalternatefee(row)
        },
        {
            dataField: 'cpFixedFee',
            text: 'Alternate Fee',
            width: 100,
            show: true,
            sort: true,
            inputType: 'number',
            hideColFilter: true,
            style_textAlign: { textAlign: "right" },
            cellRenderer: (row) => currencyFormat(row.cpFixedFee)
        },
        {
            dataField: 'totalFees',
            text: 'Total Charges',
            width: 100,
            show: true,
            sort: true,
            inputType: 'number',
            hideColFilter: true,
            style_textAlign: { textAlign: "right" },
            cellRenderer: (row) => currencyFormat(row.totalFees)
        },
       /* {
            dataField: 'carryOver',
            text: 'Previous Balance',
            width: 100,
            sort: false,
            show: false,
            inputType: 'number',
            hideColFilter: true,
            style_textAlign: { textAlign: "right" },
            cellRenderer: (row) => cellrendertooltip(row)
        },*/
        {
            dataField: 'total340BNetRemitDue',
            text: 'Total 340B Net Remit Due',
            width: 100,
            show: true,
            sort: true,
            inputType: 'number',
            hideColFilter: true,
            style_textAlign: { textAlign: "right" },
            cellRenderer: (row) => currencyFormat(row.total340BNetRemitDue)
        }
    ];

    let data = [];

    return (
        <div
            className='table-card scroll-table position-relative border-0  rounded pt-0'
            fixed-height={data.length > 0 ? 'fixed-height' : 'default'}
        >
            <ReactStrapTable
                id='pendingfiles-sf'
                fixedColumn
                hasPagination
                data={tabledata}
                columns={maincol}
                filters={filters}
                totalRecords={totalSize}
                pageSize={filters.pageSize}
                currentPage={filters.currentPage}
                handleSort={onHandleSort}
                onPageSizeChange={onPageSizeChange}
                onPageChange={onHandlePageChange}
                reset={reset}
                onFilterCancel={handleFilterCancel}
                onSearchColumnFilters={handleSearchColumnFilters}
                onExport={handleExport}
                handleFilterReset={handleFilterReset}
            />
        </div>
    );
};

export default InvoiceTable;