const StateReducer = (state, action) => {
  switch (action.type) {
    case "TENTANT_ID":
      return {
        ...state,
        claimListTenantID: action.payload
      };
    case "SET_LOADING":
      return {
        ...state,
        loading: action.payload,
      };
    case "SET_DRAWER":
      return {
        ...state,
        hasDrawer: action.payload,
      };
    case 'CLAIM_LIST_FILTERS':
      return {
        ...state,
        claimFilters: action.payload
      };
    case 'PROVIDERS_LIST_FILTERS':
      return {
        ...state,
        providerFilters: action.payload
      };
    case 'CLAIM_LISTS':
      return {
        ...state,
        claimList: {
          data: action.payload.data,
          records: action.payload.records
        }
      };
    case 'PROVIDERS_LISTS':
      return {
        ...state,
        providerList: {
          data: action.payload.data,
          records: action.payload.records
        }
      };
    default:
      return { ...state };
  };
};

export default StateReducer;