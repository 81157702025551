import React, { useState, useEffect } from "react";
import { InputGroup, Input } from "reactstrap";
import DatePicker from "react-datepicker";
import moment from "moment";

const ColumnFilters = (props) => {
  const { col, onColumnFilterChange, reset } = props;
  const [filterValue, setFilterValue] = useState();
  const [filterType, setFilterType] = useState("");

  useEffect(() => {
    setFilterValue("");
    setFilterType("");
  }, [reset]);

  const handleInputChange = (e, col, type) => {
    setFilterValue(e.target.value);
    onColumnFilterChange(e, col, type, filterType);
  };

  const handleDateChange = (date) => {
    const formatDate = moment(date).format("YYYY-MM-DD");
    setFilterValue(date);
    const event = { target: { value: formatDate } };
    onColumnFilterChange(event, col, "field", filterType);
  };

  const handleSelectChange = (e, col, type) => {
    setFilterType(e.target.value);
    onColumnFilterChange(e, col, type, e.target.value);
  };

  const renderInput = () => {
    if (col.inputType === "date") {
      return (
        <DatePicker
          selected={filterValue}
          onChange={handleDateChange}
          className="border-0 w-100 date-picker"
          placeholderText="MM/DD/YYYY"
        />
      );
    };
    return (
      <Input
        type={col.inputType === "number" ? "text" : col.inputType}
        placeholder={col.text}
        value={filterValue}
        onChange={(e) => handleInputChange(e, col, "field")}
      />
    );
  };

  return (
    <InputGroup className="table-filter-group">
      {renderInput()}
      <div style={{ zIndex: 0 }}>
        <Input
          type="select"
          className="table-filter-icon cursor-pointer"
          value={filterType}
          onChange={(e) => handleSelectChange(e, col, "select")}
        >
          {col.inputType !== "date" && col.inputType !== "pstext" && col.inputType !== "number" && <option value={1}>Equals to</option>}
          {col.inputType === "number" && <option value={13}>Equals to</option>}
          {col.inputType === "text" && <option value={3}>Contains</option>}
          {col.inputType === "pstext" && <option value={3}>Contains</option>}
          {col.inputType === "date" && <option value={12}>Contains</option>}
          {col.inputType === "text" && <option value={4}>Starts with</option>}
          {col.inputType === "pstext" && <option value={4}>Starts with</option>}
          {col.inputType === "text" && col.inputType !== "pstext" && <option value={5}>Ends with</option>}
          {(col.inputType === "number" || col.inputType === "date") && (
            <option value={6}>Less than or equals to</option>
          )};
          {(col.inputType === "number" || col.inputType === "date") && (
            <option value={7}>Greater than or equals to</option>
          )};
        </Input>
      </div>
    </InputGroup>
  );
};

export default ColumnFilters;