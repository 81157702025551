import Header from "containers/Header";
import React from "react";
import { Col, Row } from "reactstrap";
import Doctor from "assets/icon/Doctor.png"
import Logo from "assets/icon/Logo.svg";
import Key from "assets/icon/key.svg";

const NotValidTab = () => {
  return (
    <div>
      <Header />
      <div className="" style={{ backgroundColor: "white" }}>
        <Row style={{ width: "45%", margin: "30px auto", border: "1px solid #706c52" }}>
          <Col md={7} style={{}}>
            <div className="py-2 align-items-center">
              <img src={Logo} />
            </div>
            <div className=" mt-2">
              <div className="d-flex align-items-center">
                <img src={Key} />
                <h5 className="f-12 text-primary font-weight-400 m-0 ms-2">
                  Un-Authorized Access
                </h5>
              </div>
              <hr></hr>
              <div className="ms-2 f-12">
                <h6 className="f-12">You do not have permission to access this page.</h6>
              </div>
            </div>


          </Col>
          <Col md={5} style={{ padding: "0" }}>
            <img className="w-100" src={Doctor} />
          </Col>
          <div>
          </div>
        </Row>
      </div>
    </div>
  );
};

export default NotValidTab;