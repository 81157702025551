import React from 'react';
import Layout from 'containers/Layout';
import OidcLogin from 'components/OidcLogin';
import NotValidTab from 'pages/NotValidTab';

export const PrivateRoute = ({ component, modules, ...rest }) => {
  debugger;
  const { id_token, token_type } =
    JSON.parse(localStorage.getItem('pharmaforce')) || {};

  if (token_type)
  {
    let jwtData = id_token.split('.')[1]
    let decodedJwtJsonData = window.atob(jwtData)
    let decodedJwtData = JSON.parse(decodedJwtJsonData)
    let roleId = decodedJwtData['roleId'];

    if(roleId != 7 && roleId != 8)
    {
      return <Layout modules={modules} />;
    }
    else
    {
      return <NotValidTab />;
    }
  } 
  return <OidcLogin />;
};