import React, { useEffect, useState } from 'react';
import ReactStrapTable from "components/reactStrapTable";
import { cellRendererCheck, currencyFormat, dateFormate, cellnumberFormat } from "utils/columns";
import { Row, Col } from "reactstrap";
import Loader from 'components/Loader';

const PaymentActivity = (props) => {

    const {
        tabledata,
        totalSize,
        onPageChange,
        apiFilter,
        refetch,
        onSearchColumnFilters,
        CoveredEntity,
        opaCode,
        Cycle,
        TotalPaymentReceived,
        PendingAmount,
        handleExport,
        loading
    } = props;

    const [reset, setReset] = useState(false);
    const [filters, setFilters] = useState(apiFilter);

    useEffect(() => {
        setFilters(apiFilter);
    }, [apiFilter]);

    useEffect(() => {
        const { token_type, access_token, profile } =
            JSON.parse(sessionStorage.getItem('pharmaforce')) || {};
    }, []);

    useEffect(() => {
        setReset((pre) => !pre);
        setFilters({
            currentPage: 1,
            pageSize: 10,
            orderBy: '',
            order: '',
            filters: [],
        });
    }, []);

    const onHandleSort = (filter, hasColFilter) => {
        setFilters(filter);
        if (hasColFilter) {
            onPageChange(filter, true);
        } else {
            onPageChange(filter);
        };
    };

    const handleFilterCancel = () => {
        refetch(true);
    };

    const onPageSizeChange = (filter, hasColFilter, colChangedFilter) => {
        if (hasColFilter) {
            setFilters(filter);
            let colFilterOn = { ...filter, filters: colChangedFilter };
            return onPageChange(colFilterOn, true);
        };
        setFilters(filter);
        onPageChange(filter);
    };

    const onHandlePageChange = (filter, hasColFilter, colChangedFilter) => {
        if (hasColFilter) {
            setFilters(filter);
            let colFilterOn = { ...filter, filters: colChangedFilter };
            onPageChange(colFilterOn, true);
        } else {
            setFilters(filter);
            onPageChange(filter);
        };
    };

    const handleSearchColumnFilters = (colFilter) => {

        if (onSearchColumnFilters) {
            const filter = { ...filters, filters: colFilter, currentPage: 1 };
            onSearchColumnFilters(filter);
            setFilters(filter);
        };
    };

    let maincol = [
        {
            dataField: 'edit',
            text: 'Options',
            // cellRenderer: (row) => renderButtons(row),
            width: 80,
            show: true,
            disabled: true,
            hideColFilter: true,
            action: true,
        },
        {
            dataField: 'pharmacy',
            text: 'Pharmacy',
            width: 170,
            show: true,
            sort: true,
            inputType: 'text',
            cellRenderer: (row) => cellRendererCheck(row.pharmacy),
        },
        {
            dataField: 'invoicedDate',
            text: 'Invoice Date',
            width: 100,
            show: true,
            sort: true,
            inputType: 'date',
            cellRenderer: (row) => dateFormate(row.invoicedDate),
        },
        {
            dataField: 'invoiceId',
            text: 'Invoice #',
            width: 100,
            show: true,
            sort: true,
            inputType: 'number',
            cellRenderer: (row) => cellRendererCheck(row.invoiceId),
        },
        {
            dataField: 'invoicePeriod',
            text: 'Invoice Period',
            width: 100,
            show: true,
            sort: true,
            inputType: 'number',
            hideColFilter: true,
            cellRenderer: (row) => cellRendererCheck(row.invoicePeriod),
        },
        {
            dataField: 'claimCount',
            text: 'Claim Count',
            width: 100,
            show: true,
            sort: true,
            inputType: 'number',
            cellRenderer: (row) => cellnumberFormat(row.claimCount),
        },
        {
            dataField: 'grossRevenue',
            text: 'Gross Revenue',
            width: 100,
            show: true,
            sort: true,
            inputType: 'number',
            hideColFilter: true,
            cellRenderer: (row) => currencyFormat(row.grossRevenue),
        },
        {
            dataField: 'dispensingFee',
            text: "Dispense Fees",
            width: 100,
            show: true,
            sort: true,
            inputType: 'number',
            hideColFilter: true,
            cellRenderer: (row) => currencyFormat(row.dispensingFee),
        },
        {
            dataField: 'invoiceAmount',
            text: 'Invoice Amount',
            width: 90,
            show: true,
            sort: true,
            inputType: 'number',
            hideColFilter: true,
            cellRenderer: (row) => currencyFormat(row.invoiceAmount),
        },
        {
            dataField: 'paymentAmount',
            text: 'Amount Paid',
            width: 80,
            show: true,
            sort: true,
            inputType: 'number',
            hideColFilter: true,
            cellRenderer: (row) => currencyFormat(row.paymentAmount),
        },
        {
            dataField: 'balanceDue',
            text: 'Balance Due',
            width: 100,
            show: true,
            sort: true,
            inputType: 'number',
            hideColFilter: true,
            cellRenderer: (row) => currencyFormat(row.balanceDue),
        },
        {
            dataField: 'paymentDate',
            text: 'Payment Date',
            width: 100,
            show: true,
            sort: true,
            inputType: 'date',
            cellRenderer: (row) => dateFormate(row.paymentDate),
        },
        {
            dataField: 'invoiceStatus',
            text: 'Invoice Status',
            width: 90,
            show: true,
            sort: true,
            inputType: 'text',
            cellRenderer: (row) => cellRendererCheck(row.invoiceStatus),
        }
    ];

    let data = [];

    return (
        <div>
            <Row className='align-items-center' style={{ height: "70px" }}>
                <Col md='auto' className='pe-3'>
                    <p className='f-14 mb-0'>
                        Covered Entity:
                        <b className='d-inline-block ps-2 font-weight-500'>
                            <span>{CoveredEntity}</span>
                        </b>
                    </p>
                </Col>
                <Col md='auto' className='pe-3'>
                    <p className='f-14 mb-0'>
                        340B ID:
                        <b className='d-inline-block ps-2 font-weight-500'>
                            <span>{opaCode}</span>
                        </b>
                    </p>
                </Col>
                <Col md='auto' className='pe-3'>
                    <p className='f-14 mb-0'>
                        Cycle:
                        <b className='d-inline-block ps-2 font-weight-500'>
                            <span>{Cycle}</span>
                        </b>
                    </p>
                </Col>
                <Col md='auto' className='pe-3'>
                    <p className='f-14 mb-0'>
                        Total Payment Received:
                        <b className='d-inline-block ps-2 font-weight-500'>
                            <span>{TotalPaymentReceived}</span>
                        </b>
                    </p>
                </Col>
                {/* <Col md='auto' className='pe-3'>
                    <p className='f-14 mb-0'>
                        Pending Amount:
                        <b className='d-inline-block ps-2 font-weight-500'>
                            <span>{PendingAmount}</span>
                        </b>
                    </p>
                </Col> */}
            </Row>
            <div className='table-card scroll-table position-relative border-0 rounded pt-0 mt-2'
                fixed-height={tabledata.length > 0 ? 'fixed-height' : 'default'}>
                {loading && <Loader />}
                <ReactStrapTable
                    id='paymentactivity'
                    fixedColumn
                    hasPagination
                    data={tabledata}
                    columns={maincol}
                    filters={filters}
                    totalRecords={totalSize}
                    pageSize={filters.pageSize}
                    currentPage={filters.currentPage}
                    handleSort={onHandleSort}
                    onPageSizeChange={onPageSizeChange}
                    onPageChange={onHandlePageChange}
                    reset={reset}
                    onFilterCancel={handleFilterCancel}
                    onSearchColumnFilters={handleSearchColumnFilters}
                    onExport={handleExport}
                />
            </div>
        </div>
    );
};

export default PaymentActivity;