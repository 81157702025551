import React from "react";
import { components } from "react-select";

export const Option = (props) => {
    return (
        <div>
            <components.Option {...props}>
                <input
                    type="checkbox"
                    checked={props.isSelected}
                    onChange={() => null}
                    style={{ marginTop: 4, marginRight: 4 }}
                />{" "}
                <label>{props.label}</label>
            </components.Option>
        </div>
    );
};

export const LimitedChipsContainer = ({ children, hasValue, ...props }) => {
    if (!hasValue) {
        return (
            <components.ValueContainer {...props}>
                {children}
            </components.ValueContainer>
        );
    }

    const CHIPS_LIMIT = 1;
    const [chips, otherChildren] = children;
    const overflowCounter = chips.slice(CHIPS_LIMIT).length;
    const displayChips = chips.slice(overflowCounter, overflowCounter + CHIPS_LIMIT);

    return (
        <components.ValueContainer {...props}>
            {displayChips}
            {otherChildren}
            {overflowCounter > 0 && `+ ${overflowCounter}`}
        </components.ValueContainer>
    );
};
